import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function fetchRecommendedCocktails(requestData) {
  const requestConfig = {
    method: 'post',
    url: `${getApiUrl()}/recommendations/cocktail`,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    data: requestData,
  };

  const { data } = await instance(requestConfig);
  return data;
}
