import React, { useState } from 'react';
import { VisitContext } from '@context';
import { useAuthContext, useLoadingContext } from '@hooks';
import {
  createVisit as createVisitApi,
  getSinglePlace as getSinglePlaceApi,
  getSingleTable as getSingleTableApi,
  cookieCheck as cookieCheckApi,
} from '@api';

function VisitProvider({ children }) {
  const { cookieCheck, createSession, setSession } = useAuthContext();
  const { setIsLoading } = useLoadingContext();
  const createVisit = async ({ placeId, tableId }) => {
    try {
      setIsLoading(true);
      const res = await createVisitApi({ placeId, tableId });

      const sessionExist = await cookieCheckApi();

      if (sessionExist.data.session) {
        setSession(sessionExist.data.session);
      }

      setIsLoading(false);
      return res;
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return false;
    }
  };

  const getSinglePlace = async placeId => {
    try {
      const res = await getSinglePlaceApi(placeId);
      return res.data.place;
    } catch (error) {
      console.error(error);

      updateVisit(prevState => {
        return {
          ...prevState,
          errorMessage:
            error?.response?.data?.errors[0]?.message || 'Something went wrong',
        };
      });

      return false;
    }
  };

  const getSingleTable = async ({ tableId }) => {
    try {
      const res = await getSingleTableApi({ tableId });
      return res.data.table;
    } catch (error) {
      console.error(error);

      updateVisit(prevState => {
        return {
          ...prevState,
          errorMessage:
            error?.response?.data?.errors[0]?.message || 'Something went wrong',
        };
      });

      return false;
    }
  };

  const getDataForVisitPage = async ({ placeId, tableId }) => {
    try {
      setIsLoading(true);
      const session = await cookieCheck();
      if (!session) await createSession();

      const placeData = await getSinglePlace(placeId);
      if (!placeData) return false;

      const tableData = await getSingleTable({ tableId });
      if (!tableData) return false;

      setDataForPage({ place: placeData, table: tableData });
      setIsLoading(false);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const setPlaceData = ({ placeId, tableId }) => {
    localStorage.setItem('place', JSON.stringify({ placeId, tableId }));
    updateVisit(prevState => {
      return { ...prevState, placeData: { placeId, tableId } };
    });
  };

  const setDataForPage = dataForPage => {
    updateVisit(prevState => {
      return { ...prevState, dataForPage };
    });
  };

  const visitState = {
    placeData: { placeId: '', tableId: '' },
    dataForPage: { place: {}, table: {} },
    errorMessage: '',
    createVisit,
    setPlaceData,
    getDataForVisitPage,
  };
  const [visit, updateVisit] = useState(visitState);

  return (
    <VisitContext.Provider value={visit}>{children}</VisitContext.Provider>
  );
}

export default VisitProvider;
