export default {
  signUp: {
    title: 'Create an account',
    labelCheckbox: 'Are you older than 18?',
    alreadyHaveAccount: 'Already have an account?',
    signUp: 'Sign Up',
  },
  signIn: {
    title: 'Howdy stranger!',
    verificationTitle: 'Check your email',
    subTitle:
      'We made choosing a drink that you like super easy. Sign in with one click to find out how.',
    register: 'Register',
    sendCode: 'SEND MAGIC LINK',
    verificationCode: 'Verification code',
    verifyCode: 'Verify code',
    or: 'OR',
  },
  placeholders: {
    name: 'Full Name',
    email: 'Your email',
    password: 'Password',
    confirmPassword: 'Password confirmation',
  },
  resetPassword: {
    title: 'Password reset',
    subTitle: 'Enter your email and we send you a password reset link.',
    confirmButtonText: 'Confirm',
  },
  errorMessage: 'Password is incorrect. Try again',
  showPassword: 'Show',
  hidePassword: 'Hide',
  cancelledAuthorization: 'Authorization Cancelled',
  requireAuthenticateForUseFeature: 'Please authenticate to use this feature',
};
