import { useState, useEffect } from 'react';

export const useCalculateProgress = () => {
  const intervalMs = 1000,
    maxProgress = 100,
    minProgress = 0,
    stepProgress = 25;

  const [progress, setProgress] = useState(minProgress);
  const [inProcessing, setInProcessing] = useState(true);

  let secondsLeftBeforeRedirect = Math.floor(
    (maxProgress - progress) / stepProgress + 1,
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress === maxProgress) {
          setInProcessing(false);
          stopProgressing();
        }
        return prevProgress + stepProgress;
      });
    }, intervalMs);

    const stopProgressing = () => clearInterval(timer);

    return () => stopProgressing();
  }, []); // eslint-disable-line

  return { progress, inProcessing, secondsLeftBeforeRedirect };
};
