import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function getOrderDetails({ orderId }) {
  const requestConfig = {
    method: 'get',
    url: `${getApiUrl()}/orders/${orderId}/details`,
    withCredentials: true,
  };

  const res = await instance(requestConfig);
  return res;
}
