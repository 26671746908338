export default {
  signUp: {
    title: 'Account aanmaken',
    placeholders: {
      name: 'Volledige naam',
      email: 'Jouw email',
    },
    labelCheckbox: 'Ben je ouder dan 18?',
    alreadyHaveAccount: 'Heb je al een account?',
    signUp: 'Registreren',
  },
  placeholders: {
    name: 'Naam',
    email: 'Jouw email',
    password: 'Wachtwoord',
    confirmPassword: 'Wachtwoord bevestiging',
  },
  signIn: {
    title: 'Sign in to continue',
    verificationTitle: 'Open je email en verifieer je opgegeven e-mailadres',
    sendCode: 'Volgende',
    verificationCode: 'Volgende',
    verifyCode: 'Verify code',
    or: 'OF',
  },
  showPassword: 'Toon',
  hidePassword: 'Verberg',
  cancelledAuthorization: 'Authorization Cancelled(NL)',
  requireAuthenticateForUseFeature:
    'Please authenticate to use this feature(NL)',
};
