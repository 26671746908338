export const getServiceWsUrl = () => {
  if (process.env.REACT_APP_USE_LOCAL_BACKEND === 'true') {
    return process.env.REACT_APP_SERVICE_WS_LOCAL_URL;
  } else {
    return window.location.host === 'app.waitless.ai'
      ? process.env.REACT_APP_SERVICE_WS_PROD_URL
      : window.location.host === 'app.waitless.dev'
      ? process.env.REACT_APP_SERVICE_WS_STAGING_URL
      : process.env.REACT_APP_SERVICE_WS_TEST_URL;
  }
};
