export default {
  initialHeading: 'Waar hou je van?',
  heading: 'Waar hou je van?',
  title: {
    sweet: 'Zoet',
    sour: 'Zuur',
    bitter: 'Bitter',
    boozy: 'Alcoholsmaak',
  },
  measurements: {
    min: 'Weinig',
    max: 'Veel',
  },
};
