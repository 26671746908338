import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function verifyPayment(data) {
  const { payment, paymentMethod } = data;
  const inputData = {
    paymentIntentId: payment.id,
    paymentMethodId: paymentMethod.id,
  };
  const req_conf = {
    method: 'put',
    url: `${getApiUrl()}/payments/${payment.id}/verify`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: inputData,
  };
  const { data: response } = await instance(req_conf);
  return response;
}
