import React, { useState } from 'react';
import { ProductContext } from '@context';
import { getProductDetails as getProductDetailsApi } from '@api';

function ProductProvider({ children }) {
  const setProduct = product => {
    updateProduct(prevState => {
      return { ...prevState, product };
    });
  };

  const getProductDetails = async productId => {
    try {
      const productDetails = await getProductDetailsApi(productId);
      return productDetails.product;
    } catch (error) {
      console.error(error);
    }
  };

  const productState = {
    setProduct,
    getProductDetails,
  };
  const [product, updateProduct] = useState(productState);

  return (
    <ProductContext.Provider value={product}>
      {children}
    </ProductContext.Provider>
  );
}

export default ProductProvider;
