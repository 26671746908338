export default {
  wrongWithAdviceList: {
    somethingWrong: 'Er is iets mis gegaan',
    youRedirected: 'Je word terug gestuurd naar de vorige page',
    second: 'secode',
    seconds: 'seconden',
    redirect: 'Redirect naar vorige pagina',
  },
  toast: {
    problemWithServer: 'Probleem met de server, probeer het later opnieuw',
  },
};
