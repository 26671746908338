export default {
  orderStatusList: {
    ordered: 'Besteld',
    preparing: 'Preparing(NL)',
    ready: 'Ready(NL)',
    out: 'Out(NL)',
    delivered: 'Delivered(NL)',
    cancelled: 'Cancelled(NL)',
  },
  orderInfo: {
    orderId: 'Order id:(NL)',
    created: 'Created:(NL)',
    status: 'Status:(NL)',
    orderAmount: 'Order amount (inc. tax): €',
  },
  buttons: {
    sendBackButton: 'send back(NL)',
    backToScanButton: 'Back to Scan(NL)',
    refundButton: 'terug betalen',
    confrimRefundButton: 'Wil je echt terug betalen?',
    thatsMeButton: "That's me(NL)",
    cancelButton: 'Cancel(NL)',
    verifyButton: 'Verify(NL)',
  },
  productRefunded: 'Product refunded(NL)',
  description: 'Description(NL)',
  table: 'Table(NL)',
  ordersDontExist: "Orders selected status don't exist(NL)",
  someErrorOccured: 'Some error occured(NL)',
  noItems: 'Er zijn hier geen items',
  changeStatusModal: 'Change status to(NL)',
  serviceItemWithoutVisitId: 'Service Item without visitId(NL)',
  areYouSureRefund: 'Are you sure you want to refund(NL)',
  totalValueRefund: 'with a total value of(NL)',
};
