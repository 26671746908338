import { createTheme } from '@mui/material';

const defaultTheme = createTheme();

const colors = {
  light: {
    snow: '#F3F4FA',
    lightCyan: '#BECDE0',
    ghostWhite: '#C5D3DF',
    freshPowder: '#FFFFFF',
    lightSteelBlue: '#78879A',
    lightBlue: '#23283866',
    lightGray: '#292D39',
    lightTransparent: '#353944',
  },
  dark: {
    grayBlue: '#6a7482',
    gainsboro: '#EBEDF5',
    darkBlue: '#303C50',
    darkNavy: '#1A222F',
    steelBlue: '#43566C',
    darkMask: '#020714b3',
    midnightBlue: '#171C2C',
    darkSlateBlue: '#232838',
    darkSlateGray: '#020714',
  },
  orange: {
    wheat: '#FFE6C0',
    orangered: '#FFA723',
    orange: '#FF9B07',
    darkOrange: '#FF8D07',
    peru: '#E99D20',
    orangeSemiTransparent: '#ff9b0733',
  },
  red: '#FF1717',
};

export default createTheme({
  palette: {
    light: {
      lightCyan: '#BECDE0',
    },
    dark: {
      midnightBlue: '#171C2C',
      darkSlateGray: '#020714',
      darkGrayish: '#292D39',
    },
  },
  fonts: {
    family: {
      primary: 'Roboto',
      secondary: 'Orbitron',
    },
    sizes: {
      font9PX: 9,
      font10PX: 10,
      font12PX: 12,
      font13PX: 13,
      font14PX: 14,
      font16PX: 16,
      font18PX: 18,
      font20PX: 20,
      font24PX: 24,
    },
  },
  colors,
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          height: 18,
          backgroundColor: colors.light.lightTransparent,
          borderRadius: defaultTheme.shape.borderRadius * 6.5,
          fontWeight: defaultTheme.typography.fontWeightMedium,
          fontSize: '14px',
          padding: '0 5px',
        },
        label: {
          padding: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.light.lightGray,
        },
      },
    },
  },
});
