import React, { useState } from 'react';
import { ElementsCoordsContext } from '@context';

function ElementsCoordsProvider({ children }) {
  const setElementsCoords = coords => {
    updateElementsCoords(prevState => {
      return {
        ...prevState,
        elementsCoords: { ...prevState.elementsCoords, ...coords },
      };
    });
  };

  const elementsCoordsState = {
    elementsCoords: {},
    setElementsCoords,
  };
  const [elementsCoords, updateElementsCoords] = useState(elementsCoordsState);

  return (
    <ElementsCoordsContext.Provider value={elementsCoords}>
      {children}
    </ElementsCoordsContext.Provider>
  );
}

export default ElementsCoordsProvider;
