export default {
  title: 'Prima keuze!',
  subTitle: ' Je drankjes zijn onderweg',
  title1:
    'Je bestelling wordt nu gemaakt en in mum van tijd gebracht naar je tafel',
  thanksText:
    'Wil je de volgende keer een advies wat nóg beter past bij jouw unieke smaak?',
  feedbackText: 'neem een moment om een rating te geven',
  repeatOrderText: 'Nogmaals bestellen',
  scanCodeText: 'Scan de QR code om nog een bestelling te doen.',
};
