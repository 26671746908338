export function filterByServiceSections(orderList, serviceSections) {
  orderList.forEach((order, index) => {
    const filteredProducts = [];
    order.orderedProductsList.forEach(product => {
      if (serviceSections[product.serviceSection]) {
        filteredProducts.push(product);
      }
    });
    orderList[index].filteredProducts = filteredProducts;
  });
  return orderList;
}
