import { v4 as uuidv4 } from 'uuid';
import { sortArrayByCriterion } from './sortArrayByCriterion';

function findRepeatingOrderElemIdx(orderElemsList, serviceElem) {
  return orderElemsList.findIndex(
    ({ order }) => order.id === serviceElem.order.id,
  );
}

function insertNewServiceElem(orderElemsList, serviceElem) {
  const { id, entity, ...formattedServiceItem } = serviceElem;

  orderElemsList.push({
    ...formattedServiceItem,
    id: uuidv4(),
    orderedProductsList: [
      {
        id: serviceElem.menuItem.id,
        name: serviceElem.menuItem.name,
        count: 1,
        serviceSection: serviceElem.serviceSection,
      },
    ],
    serviceItemsIds: [serviceElem.id],
  });
}

function findRepeatingOrderedProductIdx(
  orderElemsList,
  foundOrderElemIdx,
  serviceElem,
) {
  return orderElemsList[foundOrderElemIdx].orderedProductsList.findIndex(
    orderedProduct => orderedProduct.id === serviceElem.menuItem.id,
  );
}

function convertServiceListToOrderList(serviceItemsList) {
  const orderItemsList = [];
  for (const serviceItem of serviceItemsList) {
    const foundOrderItemIdx = findRepeatingOrderElemIdx(
      orderItemsList,
      serviceItem,
    );

    if (foundOrderItemIdx < 0) {
      insertNewServiceElem(orderItemsList, serviceItem);
    } else {
      const foundOrderedProductIdx = findRepeatingOrderedProductIdx(
        orderItemsList,
        foundOrderItemIdx,
        serviceItem,
      );

      if (foundOrderedProductIdx >= 0) {
        orderItemsList[foundOrderItemIdx].serviceItemsIds.push(serviceItem.id);

        orderItemsList[foundOrderItemIdx].orderedProductsList[
          foundOrderedProductIdx
        ].count++;
      } else {
        orderItemsList[foundOrderItemIdx].serviceItemsIds.push(serviceItem.id);

        orderItemsList[foundOrderItemIdx].orderedProductsList.push({
          id: serviceItem.menuItem.id,
          name: serviceItem.menuItem.name,
          count: 1,
          serviceSection: serviceItem.serviceSection,
        });
      }
    }
  }

  return orderItemsList;
}

export function calculateMinutesPast(dateTimeISOFormat) {
  const msecsDifference = Date.now() - new Date(dateTimeISOFormat).getTime();
  return Math.floor(msecsDifference / 60000);
}

export function defineIsMinutesPast({
  minutesPast,
  statusOrder,
  comparingMinutesCount,
}) {
  if (statusOrder === 'delivered') return false;
  return minutesPast >= comparingMinutesCount;
}

export function formateServiceItemsList(
  fetchedServiceItemsList,
  statusServiceItem,
) {
  const filteredServiceItemsListByStatus = fetchedServiceItemsList.filter(
    serviceItem => serviceItem.status === statusServiceItem,
  );
  const convertedServiceItemsList = convertServiceListToOrderList(
    filteredServiceItemsListByStatus,
  );

  const sortedOrderItemsList = sortArrayByCriterion(
    convertedServiceItemsList,
    'created',
  );

  return sortedOrderItemsList;
}

export function updateServiceItemsStatus(
  initialServiceElemsList,
  updatedServiceElemsList,
) {
  for (const updatedServiceItem of updatedServiceElemsList) {
    const updatedServiceElemIdx = initialServiceElemsList.findIndex(
      serviceElem => serviceElem.id === updatedServiceItem.id,
    );

    const updatedServiceElem = initialServiceElemsList.find(
      serviceElem => serviceElem.id === updatedServiceItem.id,
    );

    if (updatedServiceElemIdx >= 0) {
      initialServiceElemsList.splice(updatedServiceElemIdx, 1, {
        ...updatedServiceElem,
        status: updatedServiceItem.status,
      });
    }
  }

  return initialServiceElemsList;
}

export function checkListsIncluding({
  wholeList,
  incompleteList,
  comparingProperty,
}) {
  return incompleteList.every(incompleteItemList => {
    return wholeList.find(
      wholeItemList =>
        wholeItemList[comparingProperty] ===
        incompleteItemList[comparingProperty],
    );
  });
}

export function filterCollectionByListIds(filteredCollection, filterListIds) {
  return filteredCollection.filter(filteredItem =>
    filterListIds.find(filterItemId => filterItemId === filteredItem.id),
  );
}
