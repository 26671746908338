export default {
  title: 'This is a standard COVID-19 check',
  subTitle: 'Please answer the questions',
  symptoms: {
    fever: 'a fever from 38 degrees',
    cough: 'a cough',
    shortness: 'shortness of breath',
    cold: 'a nose of cold',
  },
  questions: {
    complaints:
      'Did you have one or more of these complaints in the past 24 hours?',
    roommateFever:
      'Do you currently have a roommate with a fever and/or shortness of breath?',
    didHaveCoronavirus:
      'Did you have the coronavirus and has it been diagnosed in the past 7 days (in a laboratory)?',
    roommateCoronavirus:
      'Do you have a roommate/family member with the new coronavirus and have you had contact with him/her in the past 14 days while he/shestill had complaints?',
    quarantine:
      'Are you in quarantine because you had direct contact with someone who has been diagnosed with the new coronavirus?',
  },
};
