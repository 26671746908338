import React, { useEffect, useState } from 'react';
import { OccasionContext } from '@context';

function OccasionProvider({ children }) {
  const setAnswer = answer => {
    updateAnswer(prevState => {
      return { ...prevState, answer };
    });
  };

  const answerState = {
    answer: '',
    setAnswer,
  };

  const [answer, updateAnswer] = useState(answerState);
  const occasionAnswer = JSON.parse(sessionStorage.getItem('occasionAnswer'));
  useEffect(() => {
    setAnswer(occasionAnswer);
  }, []); // eslint-disable-line
  useEffect(() => {
    // Update the browser Session Storage with the whole `session`
    sessionStorage.setItem('occasionAnswer', JSON.stringify(answer.answer));
  }, [answer]);

  return (
    <OccasionContext.Provider value={answer}>
      {children}
    </OccasionContext.Provider>
  );
}

export default OccasionProvider;
