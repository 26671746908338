import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function verifyEmail({ email, code }) {
  const requestData = {
    email,
    verificationCode: code,
  };

  const requestConfig = {
    method: 'put',
    url: `${getApiUrl()}/auth/verify-email`,
    withCredentials: true,
    data: requestData,
  };

  const res = await instance(requestConfig);
  return res;
}
