import { createContext } from 'react';

export const ToastNotificationContext = createContext({
  toastNotification: {
    isShowToast: false,
    toastType: 'error',
    toastMessage: '',
    toastKeyTranslate: '',
    errorCode: '',
  },
  setToastNotification: toastNotification => {},
});
