import React, { useState } from 'react';
import { LoadingContext } from '@context';

function LoadingProvider({ children }) {
  const setIsLoading = bool => {
    updateLoading(prevState => {
      return { ...prevState, isLoading: bool };
    });
  };

  const loadingState = {
    isLoading: false,
    setIsLoading,
  };

  const [loading, updateLoading] = useState(loadingState);

  return (
    <LoadingContext.Provider value={loading}>
      {children}
    </LoadingContext.Provider>
  );
}

export default LoadingProvider;
