import React, { useState } from 'react';
import _ from 'lodash';
import {
  fetchMenuSections,
  fetchMenuStructure,
  getMenuSections,
  fetchMenuServiceSections,
} from '@api';
import { useToastNotificationContext, useLoadingContext } from '@hooks';
import { MenuContext } from '@context';

function MenuProvider({ children }) {
  const { setIsLoading } = useLoadingContext();
  const { setToastNotification } = useToastNotificationContext();

  const setFetchedMenu = fetchedMenu => {
    updateMenu(prevState => {
      return { ...prevState, fetchedMenu };
    });
  };
  const setFetchedStructure = fetchedStructure => {
    updateMenu(prevState => {
      return { ...prevState, fetchedStructure };
    });
  };
  const fetchMenuSectionsListForService = async () => {
    try {
      setIsLoading(true);
      const fetchedSections = await fetchMenuServiceSections();
      const fetchedStructure = await getMenuSections();
      setFetchedStructure(fetchedStructure.sections);
      setFetchedMenu(fetchedSections.items);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      setToastNotification({
        isShowToast: true,
        toastType: 'error',
        toastMessage: error.response?.data?.message,
        toastKeyTranslate: 'wrongWithAdviceList',
        errorCode: error.response,
      });

      return false;
    }
  };
  const fetchMenuSectionsList = async () => {
    try {
      setIsLoading(true);
      const fetchedSections = await fetchMenuSections();
      const fetchedStructure = await fetchMenuStructure();
      const newFetchedMenu = _.cloneDeep(fetchedStructure.sections);
      const newFetcheSections = _.cloneDeep(fetchedSections.items);

      setFetchedStructure(newFetchedMenu);
      setFetchedMenu(newFetcheSections);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      setToastNotification({
        isShowToast: true,
        toastType: 'error',
        toastMessage: error.response?.data?.message,
        toastKeyTranslate: 'wrongWithAdviceList',
        errorCode: error.response,
      });

      return false;
    }
  };
  const menuState = {
    fetchedMenu: [],
    fetchedStructure: [],
    fetchMenuSectionsList,
    fetchMenuSectionsListForService,
  };

  const [menu, updateMenu] = useState(menuState);

  return <MenuContext.Provider value={menu}>{children}</MenuContext.Provider>;
}

export default MenuProvider;
