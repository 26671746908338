import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useLoadingContext } from '@hooks';
import { purposesLoadingSpinner } from '@constants';
import { useStyles, useStylesCircularProgress } from './styles';

function LoadingSpinner({ purpose }) {
  const { t } = useTranslation('loadingScreen');
  const { isLoading } = useLoadingContext();
  const classesCircularProgress = useStylesCircularProgress();
  const classes = useStyles();
  const {
    regular: regularPurposeLoadingSpinner,
    additional: additionalPurposeLoadingSpinner,
  } = purposesLoadingSpinner;

  const checkConditionDisplayingComponent = (
    purposeCriteria,
    isLoadingCriteria,
  ) => {
    const regularSpinner = purposeCriteria === regularPurposeLoadingSpinner;
    const loadingSpinner =
      purposeCriteria === additionalPurposeLoadingSpinner && isLoadingCriteria;

    if (regularSpinner) return true;
    if (loadingSpinner) return true;
    return false;
  };

  const conditionDisplayComponent = checkConditionDisplayingComponent(
    purpose,
    isLoading,
  );

  const conditionComponentIsLoading =
    purpose === additionalPurposeLoadingSpinner && isLoading;

  useEffect(() => {
    if (conditionComponentIsLoading) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [conditionComponentIsLoading]);

  return (
    <>
      {conditionDisplayComponent && (
        <div
          className={
            conditionComponentIsLoading
              ? clsx(classes.screen, classes.screenLoading)
              : classes.screen
          }
        >
          <div className={classes.content}>
            <div className={classes.loader}>
              <h3 className={classes.text}>{t('text')}</h3>
              <CircularProgress
                classes={classesCircularProgress}
                thickness={1}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoadingSpinner;
