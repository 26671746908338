import {
  slideInLeft,
  slideInRight,
  slideInDown,
  slideInUp,
} from 'react-animations';
import Radium from 'radium';

export const ANIMATE_MAP = {
  fromLeft: {
    animationDelay: '1s',
    animation: 'x .5s',
    animationName: Radium.keyframes(slideInLeft, 'slideInLeft'),
  },
  fromRight: {
    animationDelay: '1s',
    animation: 'x .7s',
    animationName: Radium.keyframes(slideInRight, 'slideInRight'),
  },
  slideInDownAnimate: {
    animation: 'x .5s',
    animationName: Radium.keyframes(slideInDown, 'slideInDown'),
  },
  slideInUpAnimate: {
    animation: 'x .5s',
    animationName: Radium.keyframes(slideInUp, 'slideInUp'),
  },
};
