import React, { useState } from 'react';
import { RatingContext } from '@context';

function RatingProvider({ children }) {
  const setRatings = rating => {
    updateRating(prevState => {
      const idxRepeatingCocktail = prevState.ratingsList.findIndex(
        item => item.cocktail === rating.cocktail,
      );

      if (idxRepeatingCocktail === -1) {
        return {
          ...prevState,
          ratingsList: prevState.ratingsList.concat(rating),
        };
      } else {
        return {
          ...prevState,
          ratingsList: prevState.ratingsList.splice(
            idxRepeatingCocktail,
            1,
            rating,
          ),
        };
      }
    });
  };

  const ratingState = {
    ratingsList: [],
    setRatings,
  };

  const [rating, updateRating] = useState(ratingState);

  return (
    <RatingContext.Provider value={rating}>{children}</RatingContext.Provider>
  );
}

export default RatingProvider;
