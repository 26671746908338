import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function initiateRefund(orderId, products) {
  const requestData = JSON.stringify({
    items: products,
  });
  const requestConfig = {
    method: 'put',
    url: `${getApiUrl()}/orders/${orderId}/initiate-refund`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: requestData,
  };

  const { data: response } = await instance(requestConfig);
  return response;
}
