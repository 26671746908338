export default {
  initialHeading:
    "Please tell us what you like and we'll find the perfect cocktail",
  heading: "Please tell us what you like and we'll find the perfect cocktail",
  'what describes you cocktail best':
    'What word describes your perfect cocktail best?',
  title: {
    Sweetness: 'Sweetness',
    Sour: 'Citrus',
    bitter: 'Bitterness',
    boozy: 'Boozy',
  },
};
