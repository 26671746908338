import { createContext } from 'react';
import { initialFlavoursContext } from './initialFlavoursContext';

const flavourList = JSON.parse(sessionStorage.getItem('flavours'));
const flavourAnswer = JSON.parse(sessionStorage.getItem('flavourAnswer'));

export const FlavoursContext = createContext({
  answer: flavourAnswer ? flavourAnswer : '',
  flavoursList: flavourList ? flavourList : initialFlavoursContext,
  setFlavoursList: flavoursList => {},
  setAnswer: answer => {},
});
