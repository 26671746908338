import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function getProductDetails(productId) {
  const requestConfig = {
    method: 'get',
    url: `${getApiUrl()}/products/${productId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  const { data } = await instance(requestConfig);
  return data;
}

export async function createProduct() {
  const requestConfig = {
    method: 'post',
    url: `${getApiUrl()}/products/`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    data: {
      name: '1 ct. test item',
      type: 'beer',
      priceComponents: [
        {
          taxRateName: 'hoog',
          taxRateAmount: 0.21,
          priceIncludingTax: 0.01,
        },
      ],
    },
  };

  const { data } = await instance(requestConfig);
  return data;
}
