import { v4 as uuidv4 } from 'uuid';

export const initialFlavoursContext = [
  {
    id: uuidv4(),
    title: 'Sweetness',
    question: '',
    value: 0,
    left: 'Dry',
    right: 'Sweet',
  },
  {
    id: uuidv4(),
    title: 'Citrus',
    question: '',
    value: 0,
    left: 'Neutral',
    right: 'Sour',
  },
  {
    id: uuidv4(),
    title: 'Boozy',
    question: 'The taste of alcohol',
    value: 0,
    left: 'Mild',
    right: 'Strong',
  },
];
