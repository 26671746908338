import { v4 as uuidv4 } from 'uuid';
import fever from '@assets/fever.png';
import cough from '@assets/cough.png';
import shortness from '@assets/shortness.png';
import cold from '@assets/cold.png';
import i18next from '../i18next/i18next';

const fixedTranslateFunction = i18next.getFixedT(null, 'checkCovidScreen');

export const symptomChipsList = [
  {
    id: uuidv4(),
    srcImg: fever,
    title: fixedTranslateFunction('symptoms.fever'),
  },
  {
    id: uuidv4(),
    srcImg: cough,
    title: fixedTranslateFunction('symptoms.cough'),
  },
  {
    id: uuidv4(),
    srcImg: shortness,
    title: fixedTranslateFunction('symptoms.shortness'),
  },
  {
    id: uuidv4(),
    srcImg: cold,
    title: fixedTranslateFunction('symptoms.cold'),
  },
];
