import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function createVisit({ placeId, tableId }) {
  const currentSession = JSON.parse(localStorage.getItem('session'));
  console.log('currentSession', currentSession);
  const requestData = {
    place: placeId,
    table: tableId,
  };

  const createSession = {
    method: 'post',
    url: `${getApiUrl()}/visits`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    data: requestData,
  };

  console.log('createSession', !!(currentSession && currentSession.visit));

  let res = !!(currentSession && currentSession.visit)
    ? currentSession.visit
    : null;
  if (res && res.table && res && res.table.id !== tableId) {
    const destroySession = {
      method: 'delete',
      url: `${getApiUrl()}/visits/${currentSession.visit.id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };

    await instance(destroySession);
  }

  if (!(currentSession && currentSession.visit)) {
    console.log('geeeeen sessie');
    const response = await instance(createSession);
    console.log('response1', response.data.visit);
    res = response.data.visit;
  }

  console.log('addasads');
  console.log('JSON.stringify(res.data)', JSON.stringify(res));
  res && localStorage.setItem('session', JSON.stringify(res));
  localStorage.setItem('psk', res.place.pspPK);
  return res;
}
