import React, { useEffect, useState, useRef } from 'react';
import { ServiceItemsContext } from '@context';
import { useLoadingContext, useToastNotificationContext } from '@hooks';
import {
  checkListsIncluding,
  updateServiceItemsStatus,
  getServiceWsUrl,
} from '@utils';

function ServiceItemsProvider({ children }) {
  const { setIsLoading } = useLoadingContext();
  const { setToastNotification } = useToastNotificationContext();

  // Storing current service items list
  // in useRef hook for using inside this provider
  const serviceItemsListRef = useRef();

  const serviceItemsState = {
    isError: false,
    webSocketInstance: {},
    serviceItemsList: [],
    serviceItemsGroupStatus: 'ordered',
    connectToWebSocket,
    updateServiceItemsGrouped,
    alterServiceItemsGroupStatus,
  };

  const [serviceItems, handleServiceItems] = useState(serviceItemsState);

  function connectToWebSocket() {
    const webSocket = new WebSocket(getServiceWsUrl());
    updateServiceItemsState('webSocketInstance', webSocket);
  }

  function sendAuthMessage() {
    const sessionLocalStorage = localStorage.getItem('session');
    const sessionLocalStorageJSON = JSON.parse(sessionLocalStorage);
    const jwt = sessionLocalStorageJSON.jwt;

    const stringifyAuthMessage = JSON.stringify({
      action: 'auth',
      jwt,
    });

    serviceItems.webSocketInstance.send(stringifyAuthMessage);
  }

  function heartbeat() {
    if (serviceItems.webSocketInstance.readyState !== 1) return;
    const pingMessage = JSON.stringify({ action: 'ping' });
    serviceItems.webSocketInstance.send(pingMessage);
    setTimeout(heartbeat, 2000);
  }

  function updateServiceItemsGrouped(webSocketInstance, serviceItemsUpdating) {
    setIsLoading(true);
    const stringifyMessage = JSON.stringify(serviceItemsUpdating);
    webSocketInstance.send(stringifyMessage);
  }

  function alterServiceItemsGroupStatus(status) {
    updateServiceItemsState('serviceItemsGroupStatus', status);
  }

  function onOpen() {
    if (serviceItems.webSocketInstance.readyState === 1) {
      sendAuthMessage();
    }
    sendAuthMessage();
  }

  function onMessage({ data }) {
    setIsLoading(false);
    const parsedMsg = JSON.parse(data);

    if (parsedMsg.statusCode !== 200) {
      console.error(parsedMsg.message);
      setToastNotification({
        isShowToast: true,
        toastType: 'error',
        toastMessage: parsedMsg.message,
        toastKeyTranslate: 'wrongWithAdviceList',
        errorCode: parsedMsg.statusCode,
      });
    }

    if (parsedMsg.statusCode >= 200 && parsedMsg.statusCode < 400) {
      updateServiceItemsState('isError', false);
    }

    if (parsedMsg.message === 'Auth Success') {
      const stringifyServiceItemsMessage = JSON.stringify({
        action: 'listServiceItems',
      });
      heartbeat();
      serviceItems.webSocketInstance.send(stringifyServiceItemsMessage);
    }

    if (parsedMsg.action === 'listServiceItems') {
      updateServiceItemsState('serviceItemsList', parsedMsg.data.serviceItems);
      serviceItemsListRef.current = parsedMsg.data.serviceItems;
    }

    if (parsedMsg.type === 'serviceItemsUpdated') {
      // Check if received updated service items list
      // present in current service items list.
      // If it doesn't, then get service items list
      const currentListIncludesUpdatedListCond = checkListsIncluding({
        wholeList: serviceItemsListRef.current,
        incompleteList: parsedMsg.serviceItems,
        comparingProperty: 'id',
      });

      if (!currentListIncludesUpdatedListCond) {
        const stringifyServiceItemsMessage = JSON.stringify({
          action: 'listServiceItems',
        });
        serviceItems.webSocketInstance.send(stringifyServiceItemsMessage);
        return;
      }

      handleServiceItems(prevState => {
        const copyServiceItemsList = [...prevState.serviceItemsList];

        const updatedServiceItemsList = updateServiceItemsStatus(
          copyServiceItemsList,
          parsedMsg.serviceItems,
        );

        return {
          ...prevState,
          serviceItemsList: updatedServiceItemsList,
        };
      });
    }

    if (parsedMsg.type === 'serviceItemsCreated') {
      handleServiceItems(prevState => {
        return {
          ...prevState,
          serviceItemsList: [
            ...prevState.serviceItemsList,
            ...parsedMsg.serviceItems,
          ],
        };
      });
    }
  }

  function onClose() {
    console.log('websocket close');
  }

  function onError(error) {
    console.error('websocket error', error);

    setToastNotification({
      isShowToast: true,
      toastType: 'error',
      toastMessage: 'Something wrong with WebSocket',
      toastKeyTranslate: '',
      errorCode: '',
    });

    updateServiceItemsState('isError', true);
  }

  function updateServiceItemsState(keyState, updatedData) {
    handleServiceItems(prevState => {
      return {
        ...prevState,
        [keyState]: updatedData,
      };
    });
  }

  useEffect(() => {
    if (!(serviceItems.webSocketInstance instanceof WebSocket)) return;

    serviceItems.webSocketInstance.addEventListener('open', onOpen);
    serviceItems.webSocketInstance.addEventListener('message', onMessage);
    serviceItems.webSocketInstance.addEventListener('error', onError);
    serviceItems.webSocketInstance.addEventListener('close', onClose);

    return () => {
      serviceItems.webSocketInstance.removeEventListener('open', onOpen);
      serviceItems.webSocketInstance.removeEventListener('message', onMessage);
      serviceItems.webSocketInstance.removeEventListener('error', onError);
      serviceItems.webSocketInstance.removeEventListener('close', onClose);
    };
  }, [serviceItems.webSocketInstance]); // eslint-disable-line

  return (
    <ServiceItemsContext.Provider value={serviceItems}>
      {children}
    </ServiceItemsContext.Provider>
  );
}

export default ServiceItemsProvider;
