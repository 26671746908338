export const getApiUrl = () => {
  if (process.env.REACT_APP_USE_LOCAL_BACKEND === 'true') {
    return process.env.REACT_APP_LOCAL_URL;
  } else {
    return window.location.host === 'app.waitless.ai'
      ? process.env.REACT_APP_PROD_URL
      : window.location.host === 'app.waitless.dev'
      ? process.env.REACT_APP_STAGING_URL
      : process.env.REACT_APP_TEST_URL;
  }
};
