import React, { useState } from 'react';
import { fetchRecommendedCocktails } from '@api';
import { CocktailContext } from '@context';
import { useToastNotificationContext, useLoadingContext } from '@hooks';

function CocktailProvider({ children }) {
  const { setIsLoading } = useLoadingContext();
  const { setToastNotification } = useToastNotificationContext();

  const setSelectedCocktail = selectedCocktail => {
    updateCocktails(prevState => {
      return { ...prevState, selectedCocktail };
    });
  };

  const setFetchedRecommendedCocktailsMap = fetchedRecommendedCocktailsMap => {
    updateCocktails(prevState => {
      return { ...prevState, fetchedRecommendedCocktailsMap };
    });
  };

  const fetchCocktailsRecommendation = async preferredFlavours => {
    try {
      setIsLoading(true);
      const fetchedRecommendationMap = await fetchRecommendedCocktails(
        preferredFlavours,
      );
      setFetchedRecommendedCocktailsMap(
        fetchedRecommendationMap.recommendation,
      );
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      console.error(error);

      setToastNotification({
        isShowToast: true,
        toastType: 'error',
        toastMessage:
          error?.response?.data?.message || 'Could not get recommendation',
        toastKeyTranslate: 'wrongWithAdviceList',
        errorCode: error?.response?.status || '',
      });

      return false;
    }
  };

  const cocktailsState = {
    selectedCocktail: {},
    setSelectedCocktail,
    fetchedRecommendedCocktailsMap: {},
    setFetchedRecommendedCocktailsMap,
    fetchCocktailsRecommendation,
  };

  const [cocktails, updateCocktails] = useState(cocktailsState);

  return (
    <CocktailContext.Provider value={cocktails}>
      {children}
    </CocktailContext.Provider>
  );
}

export default CocktailProvider;
