import React, { useState } from 'react';
import { getOrderDetails } from '@api';
import { OrderContext } from '@context';

function OrderProvider({ children }) {
  const setOrder = order => {
    localStorage.setItem('order', JSON.stringify(order));
    updateOrder(prevState => {
      return { ...prevState, order };
    });
  };

  const fetchOrderData = async ({ orderId }) => {
    try {
      updateOrder(prevState => {
        return { ...prevState, isLoadingOrderItemData: true };
      });
      const res = await getOrderDetails({ orderId });

      updateOrder(prevState => {
        return {
          ...prevState,
          orderItemData: res.data.order,
          isLoadingOrderItemData: false,
        };
      });
    } catch (error) {
      updateOrder(prevState => {
        return { ...prevState, isLoadingOrderItemData: false };
      });
      console.error(error);
    }
  };

  const orderState = {
    order: JSON.parse(localStorage.getItem('order')) || [],
    orderItemData: {},
    isLoadingOrderItemData: false,
    fetchOrderData,
    setOrder,
  };

  const [order, updateOrder] = useState(orderState);

  return (
    <OrderContext.Provider value={order}>{children}</OrderContext.Provider>
  );
}

export default OrderProvider;
