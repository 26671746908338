import React from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import { WithToastNotifications } from '@HOC';
import Router from '../../router/router';
import theme from '../../theme/theme';

const muiCache = createCache({
  key: 'css',
  prepend: true,
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CacheProvider value={muiCache}>
        <ToastContainer autoClose={2500} />
        <Router />
      </CacheProvider>
    </ThemeProvider>
  );
}

export default WithToastNotifications(App);
