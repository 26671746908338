import { v4 as uuidv4 } from 'uuid';
import coughSmall from '@assets/cough-small.png';
import coronavirus from '@assets/coronavirus.png';
import roommate from '@assets/roommate.png';
import quarantine from '@assets/quarantine.png';
import i18next from '../i18next/i18next';
import { symptomChipsList } from './symptomChipsList';

const fixedTranslateFunction = i18next.getFixedT(null, 'checkCovidScreen');

export const covidQuestionList = [
  {
    id: uuidv4(),
    srcImg: symptomChipsList,
    question:
      'Did you have one or more of these complaints in the past 24 hours?',
    questionText: fixedTranslateFunction('questions.complaints'),
    statusQuestion: {
      questionAnswered: false,
      answer: '',
    },
  },
  {
    id: uuidv4(),
    srcImg: coughSmall,
    question:
      'Do you currently have a roommate with a fever and/or shortness of breath?',
    questionText: fixedTranslateFunction('questions.roommateFever'),
    statusQuestion: {
      questionAnswered: false,
      answer: '',
    },
  },
  {
    id: uuidv4(),
    srcImg: coronavirus,
    question:
      'Did you have the coronavirus and has it been diagnosed in the past 7 days (in a laboratory)?',
    questionText: fixedTranslateFunction('questions.didHaveCoronavirus'),
    statusQuestion: {
      questionAnswered: false,
      answer: '',
    },
  },
  {
    id: uuidv4(),
    srcImg: roommate,
    question:
      'Do you have a roommate/family member with the new coronavirus and have you had contact with him/her in the past 14 days while he/shestill had complaints?',
    questionText: fixedTranslateFunction('questions.roommateCoronavirus'),
    statusQuestion: {
      questionAnswered: false,
      answer: '',
    },
  },
  {
    id: uuidv4(),
    srcImg: quarantine,
    question:
      'Are you in quarantine because you had direct contact with someone who has been diagnosed with the new coronavirus?',
    questionText: fixedTranslateFunction('questions.quarantine'),
    statusQuestion: {
      questionAnswered: false,
      answer: '',
    },
  },
];
