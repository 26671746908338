import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function verifyPaymentSca({ paymentIntentId, paymentId }) {
  const inputData = {
    paymentIntentId: paymentIntentId,
  };

  const req_conf = {
    method: 'put',
    url: `${getApiUrl()}/payments/${paymentId}/verify`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: inputData,
  };
  const { data: response } = await instance(req_conf);
  return response;
}
