export function displayInputLabel({ id, inputList }) {
  return inputList.map(input => {
    if (input.id === id) {
      return {
        ...input,
        isLabelInputDisplayed: true,
        isPlaceholderInputDisplayed: false,
      };
    }
    return input;
  });
}

export function displayInputPlaceholder({ id, inputList }) {
  return inputList.map(input => {
    if (input.id === id) {
      return {
        ...input,
        isLabelInputDisplayed: false,
        isPlaceholderInputDisplayed: true,
      };
    }
    return input;
  });
}

export function toggleDisplayInputPlaceholder({ id, inputList }) {
  return inputList.map(item => {
    if (item.id === id) {
      return {
        ...item,
        isPlaceholderInputDisplayed: !item.isPlaceholderInputDisplayed,
      };
    }
    return item;
  });
}

export function setInputValue({ id, inputList, value }) {
  return inputList.map(item => {
    if (item.id === id) {
      return {
        ...item,
        value,
        isLabelInputDisplayed: value.length > 0,
      };
    }
    return item;
  });
}

export function toggleDisplayPassword({ id, inputList }) {
  return inputList.map(item => {
    if (item.id === id) {
      return {
        ...item,
        type: !item.isShowPassword ? 'text' : 'password',
        isShowPassword: !item.isShowPassword,
      };
    }
    return item;
  });
}

export function switchBetweenLabelAndPlaceholder({
  condition,
  setLabel,
  setPlaceholder,
}) {
  if (condition) {
    setLabel(true);
    setPlaceholder(false);
  } else {
    setLabel(false);
    setPlaceholder(true);
  }
}
