import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function createPayment(orderId, tipAmount) {
  const req_pay = {
    method: 'post',
    url: `${getApiUrl()}/payments`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: { order: orderId, tipAmount },
  };
  const { data: response } = await instance(req_pay);

  return response;
}
