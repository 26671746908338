import React from 'react';
import FlavoursProvider from './flavours/FlavoursProvider';
import CocktailProvider from './cocktail/CocktailProvider';
import LoadingProvider from './loading/LoadingProvider';
import ToastNotificationProvider from './toastNotification/ToastNotificationProvider';
import RatingProvider from './rating/RatingProvider';
import AuthProvider from './auth/AuthProvider';
import OccasionProvider from './occasion/OccasionProvider';
import MenuProvider from './menu/MenuProvider';
import OrderProvider from './order/OrderProvider';
import ServiceItemsProvider from './serviceItems/ServiceItemsProvider';
import VisitProvider from './visit/VisitProvider';
import ElementsCoordsProvider from './elementsCoords/ElementsCoordsProvider';
import ProductProvider from './product/ProductProvider';
import DrawerProvider from './drawer/DrawerProvider';

function MainContextProvider({ children }) {
  return (
    <LoadingProvider>
      <ToastNotificationProvider>
        <CocktailProvider>
          <FlavoursProvider>
            <OccasionProvider>
              <RatingProvider>
                <OrderProvider>
                  <MenuProvider>
                    <AuthProvider>
                      <VisitProvider>
                        <ServiceItemsProvider>
                          <ElementsCoordsProvider>
                            <DrawerProvider>
                              <ProductProvider>{children}</ProductProvider>
                            </DrawerProvider>
                          </ElementsCoordsProvider>
                        </ServiceItemsProvider>
                      </VisitProvider>
                    </AuthProvider>
                  </MenuProvider>
                </OrderProvider>
              </RatingProvider>
            </OccasionProvider>
          </FlavoursProvider>
        </CocktailProvider>
      </ToastNotificationProvider>
    </LoadingProvider>
  );
}

export default MainContextProvider;
