import React, { useState } from 'react';
import { ToastNotificationContext } from '@context';

function ToastNotificationProvider({ children }) {
  const setToastNotification = toastNotification => {
    handleToastNotification(prevState => {
      return { ...prevState, toastNotification };
    });
  };

  const toastNotificationState = {
    toastNotification: {
      isShowToast: false,
      toastType: 'error',
      toastMessage: '',
      toastKeyTranslate: '',
      errorCode: '',
    },
    setToastNotification,
  };

  const [toastNotification, handleToastNotification] = useState(
    toastNotificationState,
  );

  return (
    <ToastNotificationContext.Provider value={toastNotification}>
      {children}
    </ToastNotificationContext.Provider>
  );
}

export default ToastNotificationProvider;
