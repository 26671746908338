import { createContext } from 'react';

export const AuthContext = createContext({
  session: {},
  setSession: session => {},
  establishSession: () => {},
  verifyEmailWithCode: () => {},
  staffCheckIn: () => {},
  cookieCheck: () => {},
  getSinglePlace: () => {},
});
