import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function fetchServiceSections() {
  const requestConfig = {
    method: 'GET',
    withCredentials: true,
    url: `${getApiUrl()}/service/sections`,
  };
  const { data } = await instance(requestConfig);
  return data;
}
