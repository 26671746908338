import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function staffCheckOut() {
  const requestConfig = {
    method: 'delete',
    url: `${getApiUrl()}/staff/check-in/`,
    withCredentials: true,
  };

  const res = await instance(requestConfig);
  // localStorage.setItem('session', JSON.stringify(res.data));
  return res;
}
