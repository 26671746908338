import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES, ANIMATE_MAP } from '@constants';

const ROUTES_MAP = Object.keys(ROUTES).reduce((obj, key, idx) => {
  obj[ROUTES[key]] = idx + 1;
  return obj;
}, {});

let prevPath = ''; // prev page path, stored in between hook inits in different components

export const useAnimatePage = () => {
  const location = useLocation();
  const [animation, setAnimation] = useState({});

  useEffect(() => {
    const currentPath = location.pathname;
    const currentPageIdx = ROUTES_MAP[currentPath];
    const prevPageIdx = ROUTES_MAP[prevPath] || 0;

    setAnimation(
      ANIMATE_MAP[
        prevPageIdx === 0
          ? {}
          : currentPageIdx >= prevPageIdx
          ? 'fromLeft'
          : 'fromRight'
      ],
    );
    prevPath = currentPath;
  }, []); // eslint-disable-line

  return animation;
};
