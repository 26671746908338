import React, { useState } from 'react';
import { AuthContext } from '@context';
import {
  useVisitContext,
  useLoadingContext,
  useToastNotificationContext,
} from '@hooks';
import {
  signInEmail,
  verifyEmail,
  deleteCookie,
  createSession as createSessionApi,
  staffCheckIn as staffCheckInApi,
  cookieCheck as cookieCheckApi,
} from '@api';

function AuthProvider({ children }) {
  const { createVisit } = useVisitContext();
  const { setIsLoading } = useLoadingContext();
  const { setToastNotification } = useToastNotificationContext();

  const cookieCheck = async () => {
    try {
      setIsLoading(true);
      const res = await cookieCheckApi();
      setIsLoading(false);
      return res.data.session;
    } catch (error) {
      setIsLoading(false);
      return false;
    }
  };

  const createSession = async () => {
    let session = await cookieCheckApi();

    try {
      setIsLoading(true);
      if (!session.id) {
        await createSessionApi();
      }
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return false;
    }
  };

  const closeSession = async () => {
    try {
      const res = await deleteCookie();
      if (res) return true;
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const establishSession = async (email, application) => {
    try {
      setIsLoading(true);
      let session = await cookieCheckApi();
      if (!session.data.session) {
        await createSessionApi();
      }

      if (email) {
        const res = await signInEmail(email, application);
        if (res.status === 200) {
          setIsLoading(false);
        }
      } else {
        const sessionExist = await cookieCheckApi();
        if (sessionExist.data.session) {
          setSession(sessionExist.data.session);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setToastNotification({
        isShowToast: true,
        toastType: 'error',
        toastMessage: error.response.data.errors[0].message,
        toastKeyTranslate: error.response.data.errors[0].message,
        errorCode: error.response.status,
      });
    }
  };

  const verifyEmailWithCode = async ({
    email,
    code,
    showToastError = true,
  }) => {
    try {
      setIsLoading(true);
      const res = await verifyEmail({ email, code });

      if (res.status !== 200) {
        setIsLoading(false);
        return false;
      }

      const sessionExist = await cookieCheckApi();

      if (sessionExist.data.session?.authenticated) {
        setSession(sessionExist.data.session);
      }

      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      setSession({});

      if (showToastError) {
        setToastNotification({
          isShowToast: true,
          toastType: 'error',
          toastMessage: error.response.data.errors[0].message,
          toastKeyTranslate: 'toast.problemWithServer',
          errorCode: error.response?.status,
        });
      }

      return false;
    }
  };

  const staffCheckIn = async placeId => {
    try {
      setIsLoading(true);
      const res = await staffCheckInApi(placeId);
      if (res.status === 200) {
        const currentSession = localStorage.getItem('session');
        const currentSessionParsed = JSON.parse(currentSession);
        const updatedSession = { ...currentSessionParsed, ...res.data };
        setSession(updatedSession);
        setIsLoading(false);
        return true;
      }
      setIsLoading(false);
      return false;
    } catch (error) {
      setIsLoading(false);
      setToastNotification({
        isShowToast: true,
        toastType: 'error',
        toastMessage: error.response.data.errors[0].message,
        toastKeyTranslate: error.response.data.errors[0].message,
        errorCode: error.response.status,
      });
      return false;
    }
  };

  const manageRouting = async ({ placeId, tableId, pathname }) => {
    try {
      setIsLoading(true);
      const resCookie = await cookieCheck();
      setIsLoading(false);

      if (!resCookie) return 'AUTH FLOW';

      if (pathname.indexOf('visit') >= 0) {
        setIsLoading(true);
        const res = await createVisit({ placeId, tableId });
        setIsLoading(false);

        if (!res) return 'LANDING PAGE';
        return 'VIEW MENU';
      }

      if (pathname.indexOf('service') >= 0) {
        setIsLoading(true);
        const res = await staffCheckIn(placeId);
        setIsLoading(false);

        if (!res) return 'LANDING PAGE';
        return 'SERVICE ITEMS';
      }
    } catch (error) {
      setIsLoading(false);
      return 'ERROR OCCURED';
    }
  };

  const setSession = session => {
    localStorage.setItem('session', JSON.stringify(session));

    updateAuth(prevState => {
      return { ...prevState, session };
    });
  };

  const authState = {
    session: JSON.parse(localStorage.getItem('session')) || {},
    establishSession,
    verifyEmailWithCode,
    staffCheckIn,
    cookieCheck,
    setSession,
    manageRouting,
    closeSession,
    createSession,
  };
  const [auth, updateAuth] = useState(authState);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
