import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAuthContext, useVisitContext } from '@hooks';

export const useManageRoutes = () => {
  const [routeDestination, setRouteDestination] = useState('');
  const [routeDirection, setRouteDirection] = useState('');
  const { manageRouting } = useAuthContext();
  const { setPlaceData } = useVisitContext();

  const { pathname } = useLocation();
  const { placeId, tableId } = useParams();

  useEffect(() => {
    const defineRouteDirection = async () => {
      const routeDirection = await manageRouting({
        placeId,
        tableId,
        pathname,
      });

      setRouteDestination(routeDirection);
    };
    defineRouteDirection();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (routeDestination.length === 0) return;

    switch (routeDestination) {
      case 'AUTH FLOW': {
        setPlaceData({ placeId, tableId: tableId ? tableId : '' });
        setRouteDirection('/auth');
        break;
      }
      case 'VIEW MENU': {
        setRouteDirection('/menu');
        break;
      }
      case 'SERVICE ITEMS': {
        setRouteDirection(`/service/${placeId}/orders`);
        break;
      }
      default: {
        setRouteDirection('/');
      }
    }
  }, [routeDestination]); // eslint-disable-line

  return routeDirection;
};
