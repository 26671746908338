export const REGULAR = 'regular';
export const POSITION_ABSOLUTE = 'positionAbsolute';
export const SILVER_BG_COLOR = 'silverBgColor';

export const MATCH_CHIP_TYPES = {
  REGULAR: 'regular',
  POSITION_ABSOLUTE: 'positionAbsolute',
  SILVER_BG_COLOR: 'silverBgColor',
  PRODUCT_CARD: 'productCard',
};
