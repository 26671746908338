export default {
  wrongWithAdviceList: {
    somethingWrong: 'Something went wrong...',
    youRedirected: 'You will be redirected to the previous page in',
    second: 'second',
    seconds: 'seconds',
    redirect: 'Redirect to the previous page...',
  },
  toast: {
    problemWithServer: 'Problem with server. Try again later',
  },
};
