import { createContext } from 'react';

export const VisitContext = createContext({
  placeData: { placeId: '', tableId: '' },
  dataForPage: {
    place: {
      taxRates: undefined,
    },
    table: {},
  },
  getDataForVisitPage: ({ placeId, tableId }) => {},
});
