export default {
  orderStatusList: {
    ordered: 'Ordered',
    preparing: 'Preparing',
    ready: 'Ready',
    out: 'Out',
    delivered: 'Delivered',
    cancelled: 'Cancelled',
  },
  orderInfo: {
    orderId: 'Order id:',
    created: 'Created:',
    status: 'Status:',
    orderAmount: 'Order amount (inc. tax): ',
  },
  buttons: {
    sendBackButton: 'send back',
    backToScanButton: 'Back to Scan',
    refundButton: 'refund',
    confrimRefundButton: 'Confirm Refund',
    thatsMeButton: "That's me",
    cancelButton: 'Cancel',
    verifyButton: 'Verify',
  },
  productRefunded: 'Product refunded',
  description: 'Description',
  table: 'Table',
  ordersDontExist: "Orders selected status don't exist",
  someErrorOccured: 'Some error occured',
  noItems: 'There are no items here',
  changeStatusModal: 'Change status to',
  serviceItemWithoutVisitId: 'Service Item without visitId',
  areYouSureRefund: 'Are you sure you want to refund',
  areYouSureRefundTheTip: 'Are you sure you want to refund the tip',
  totalValueRefund: 'with a total value of',
  andTip: 'and tip',
};
