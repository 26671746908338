export default {
  showMyCocktail: 'Show my cocktail',
  cocktailMenu: 'Menu',
  cocktailSetup: 'Cocktail finder',
  addToOrder: 'Add to my order',
  chooseAnotherTaste: 'Try again',
  bringCocktails: 'Bring me my cocktails',
  moreCocktails: 'More cocktails?',
  confirmResult: 'Confirm Result',
  drinksHistory: 'Your drinks history',
  confirm: 'yes',
  decline: 'no',
};
