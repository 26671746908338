import pick from 'lodash/pick';

export function getTasteMap(tasteList, tasteName, tasteValue) {
  const rawFlavoursList = tasteList.map(taste =>
    pick(taste, [tasteName, tasteValue]),
  );

  const result = rawFlavoursList.reduce((previousValue, item) => {
    previousValue[item[tasteName].toLowerCase()] = item[tasteValue];
    return previousValue;
  }, {});
  return result;
}
