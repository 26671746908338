import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function createSession() {
  const requestConfig = {
    method: 'post',
    withCredentials: true,
    url: `${getApiUrl()}/auth`,
  };
  const res = await instance(requestConfig);
  return res;
}
