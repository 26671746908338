export default {
  title: 'Great choice!',
  subTitle: ' Your order is being prepared and is brought to you asap!',
  title1: 'Your order will be delivered soon to Table 13',
  thanksText: 'Thank you for ordering. Your cocktail will arrive shortly.',
  feedbackText:
    'After you have received and tasted your cocktail, please take a moment to give us feedback on the recommendation.',
  repeatOrderText:
    'Want to order the same cocktail again? Or see our recommendations again? Please use the buttons below.',
  scanCodeText:
    'Alternatively, scan the QR code on your table again to order a different cocktail.',
};
