import React, { useEffect, useState } from 'react';
import { FlavoursContext, initialFlavoursContext } from '@context';

function FlavoursProvider({ children }) {
  const setAnswer = answer => {
    updateFlavours(prevState => {
      return { ...prevState, answer };
    });
  };

  const setFlavours = (flavoursList, answer) => {
    updateFlavours(prevState => {
      return { ...prevState, flavoursList, answer };
    });
  };

  const flavoursState = {
    answer: '',
    flavoursList: initialFlavoursContext,
    setAnswer,
    setFlavours,
  };

  const [flavours, updateFlavours] = useState(flavoursState);
  const flavourAnswer = JSON.parse(sessionStorage.getItem('flavourAnswer'));

  useEffect(() => {
    setAnswer(flavourAnswer);
  }, []); // eslint-disable-line

  useEffect(() => {
    sessionStorage.setItem('flavours', JSON.stringify(flavours.flavoursList));
    sessionStorage.setItem('flavourAnswer', JSON.stringify(flavours.answer));
  }, [flavours]);

  return (
    <FlavoursContext.Provider value={flavours}>
      {children}
    </FlavoursContext.Provider>
  );
}

export default FlavoursProvider;
