import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languages from '../localization';

const options = {
  order: [
    'navigator',
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'htmlTag',
    'path',
    'subdomain',
  ],

  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',

  resources: languages,
  detection: options,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
