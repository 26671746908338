import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function cancelPayment(paymentId) {
  const req_conf = {
    method: 'delete',
    url: `${getApiUrl()}/payments/${paymentId}`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const { data: response } = await instance(req_conf);
  return response;
}
