import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function staffCheckIn(placeIdFromParams) {
  const requestConfig = {
    method: 'put',
    url: `${getApiUrl()}/staff/check-in/${placeIdFromParams}`,
    withCredentials: true,
  };

  const res = await instance(requestConfig);
  localStorage.setItem('session', JSON.stringify(res.data));
  return res;
}
