import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function refundPayment(orderId, isTipChecked) {
  const requestData = JSON.stringify({
    order: orderId,
    refundTip: isTipChecked,
  });

  const request = {
    method: 'post',
    url: `${getApiUrl()}/payments/refund`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: requestData,
  };
  const { data: response } = await instance(request);

  return response;
}
