import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// import ReactGA from 'react-ga';
import './i18next/i18next';
import App from './components/App';
import MainContextProvider from './providers';
import * as serviceWorker from './serviceWorker';
import './assets/index.css';

/*
ReactGA.initialize('253388151');
ReactGA.pageview(window.location.pathname + window.location.search);
*/

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      'https://0e7eea0b212a4620a8d2237a062eb027@o174068.ingest.sentry.io/5525578',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <MainContextProvider>
    <App />
  </MainContextProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// Hot Module Replacement
// https://webpack.js.org/guides/hot-module-replacement/
if (module.hot) {
  module.hot.accept();
}
