import startScreen from './startScreen/startScreen';
import visitScreen from './visitScreen/visitScreen';
import sliderScreen from './sliderScreen/sliderScreen';
import button from './button/button';
import adviceScreen from './adviceScreen/adviceScreen';
import cocktailDetailsScreen from './cocktailDetailsScreen/cocktailDetailsScreen';
import modal from './modal/modal';
import thankYouScreen from './thankYouScreen/thankYouScreen';
import loadingScreen from './loadingScreen/loadingScreen';
import errorNotifications from './errorNotifications/errorNotifications';
import notFoundScreen from './notFoundScreen/notFoundScreen';
import ordersScreen from './ordersScreen/ordersScreen';
import matchChip from './matchChip/matchChip';
import auth from './auth/auth';
import checkCovidScreen from './checkCovidScreen/checkCovidScreen';
import occasionScreen from './occasionScreen/occasionScreen';
import accountSettings from './accountSettings/accountSettings';
import drinksHistory from './drinksHistory/drinksHistory';
import pointOfService from './pointOfService/pointOfService';
import checkoutPage from './checkoutPage/checkoutPage';
import menu from './menu/menu';
import closedPage from './closedPage/closedPage';

export default {
  startScreen,
  button,
  checkoutPage,
  visitScreen,
  sliderScreen,
  adviceScreen,
  cocktailDetailsScreen,
  modal,
  thankYouScreen,
  loadingScreen,
  errorNotifications,
  notFoundScreen,
  ordersScreen,
  occasionScreen,
  matchChip,
  auth,
  checkCovidScreen,
  accountSettings,
  drinksHistory,
  pointOfService,
  menu,
  closedPage,
};
