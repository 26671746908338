export default {
  title: 'Standaard COVID-19 check',
  subTitle: 'Beantwoord alle vragen',
  symptoms: {
    fever: '38 graden koorts',
    cough: 'een hoest',
    shortness: 'kortademig',
    cold: 'een neusverkoudheid',
  },
  questions: {
    complaints:
      'Had je 1 of meerdere van deze klachten in de afgelopen 24 uur?',
    roommateFever:
      'Heb je op dit moment een huisgenoot met koorts of kortademigheid?',
    didHaveCoronavirus: 'Heb je op dit moment het coronavirus?',
    roommateCoronavirus:
      'Heb je op dit moment een huisgenoot met het coronavirus waar je in de afgelopen 14 dagen nog contact mee hebt gehad?',
    quarantine: 'Zit je op dit moment in quarantaine?',
  },
};
