import { useState, useEffect } from 'react';
import { ANIMATE_MAP } from '@constants';

const tabsMap = {
  ordered: 0,
  preparing: 1,
  ready: 2,
  out: 3,
  delivered: 4,
  cancelled: 5,
};

export const useAnimateServiceItem = serviceItemsGroupStatus => {
  const [animation, setAnimation] = useState(ANIMATE_MAP.fromLeft);
  const [prevTab, setPrevTab] = useState('preparing');

  useEffect(() => {
    const currentTabIdx = tabsMap[serviceItemsGroupStatus];
    const prevTabIdx = tabsMap[prevTab] || 0;

    setAnimation(
      ANIMATE_MAP[currentTabIdx >= prevTabIdx ? 'fromLeft' : 'fromRight'],
    );

    setPrevTab(serviceItemsGroupStatus);
  }, [serviceItemsGroupStatus]); // eslint-disable-line

  return { animation, setAnimation };
};
