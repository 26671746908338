import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function getSinglePlace(placeId) {
  const requestConfig = {
    method: 'get',
    url: `${getApiUrl()}/places/${placeId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  const res = await instance(requestConfig);
  return res;
}
