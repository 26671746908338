import React, { useState } from 'react';
import { useLoadingContext } from '@hooks';
import LoadingSpinner from '@components/LoadingSpinner';
import { purposesLoadingSpinner } from '@constants';

export function WithStaffCheckIn(WrappedComponent) {
  function HOC() {
    const [isStaff] = useState(true);
    const { isLoading } = useLoadingContext();

    const conditionDisplayLoadingSpinner =
      isLoading || typeof isStaff === 'undefined';

    if (conditionDisplayLoadingSpinner) {
      return <LoadingSpinner purpose={purposesLoadingSpinner.additional} />;
    }

    if (isStaff) return <WrappedComponent />;
  }

  return HOC;
}
