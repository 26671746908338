import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function signInEmail(email, application) {
  const requestData = {
    email,
    application,
  };

  const requestConfig = {
    method: 'put',
    url: `${getApiUrl()}/auth/signin-email`,
    withCredentials: true,
    data: requestData,
  };

  const res = await instance(requestConfig);
  return res;
}
