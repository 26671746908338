export default {
  showMyCocktail: 'Toon mijn cocktail',
  cocktailMenu: 'Bekijk menu',
  cocktailSetup: 'Cocktail adviestool',
  addToOrder: 'Voeg toe aan mijn bestelling',
  chooseAnotherTaste: 'Andere keuze maken',
  bringCocktails: 'Breng mijn drankjes',
  moreCocktails: 'Meer cocktails',
  confirmResult: 'Bevestig',
  drinksHistory: 'Vorige drankjes',
  confirm: 'ja',
  decline: 'nee',
};
