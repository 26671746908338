export const ROUTES = {
  // CLIENT Part Application Routes
  START_SCREEN: '/',
  SLIDER_SCREEN: '/slider-screen',
  OCCASION_SCREEN: '/occasion-screen',
  ADVICE_SCREEN: '/advice-screen',
  ACCOUNT_SETTINGS_SCREEN: '/account-settings-screen',
  CHECK_COVID_SCREEN: '/check-covid-screen',
  DRINKS_HISTORY_SCREEN: '/drinks-history-screen',
  ERROR_SCREEN: '/error-screen',
  ERROR_VISIT_SCREEN: '/error-visit-screen',
  THANK_YOU_SCREEN: '/thank-you-screen',
  MENU_SCREEN: '/menu',
  ORDERS_SCREEN: '/orders-screen',
  SIGN_IN_SCREEN: '/sign-in-screen',
  VERIFICATION_SCREEN: '/verification',
  AUTH_SCREEN: '/auth',
  VISIT_SCREEN: '/visit/:placeId/:tableId',
  VERIFY_EMAIL_SCREEN: '/verify-email/:email/:token',

  // POINT OF SERVICE Part Application Routes
  POINT_OF_SERVICE_ROUTER: '/service/:placeId',
  POINT_OF_SERVICE_ORDERS: '/service/:id/orders',
  MENU_MANAGEMENT: '/menu-management',
  EDIT_MENU_ITEM: '/menu-management/edit/:productId',
};
