import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useToastNotificationContext } from '@hooks';
import 'react-toastify/dist/ReactToastify.css';

export function WithToastNotifications(WrappedComponent) {
  function HOC() {
    const {
      toastNotification,
      setToastNotification,
    } = useToastNotificationContext();
    const { isShowToast, toastType, toastMessage } = toastNotification;

    const resetResponseStatusContext = () => {
      setToastNotification({
        isShowToast: false,
        toastType: 'error',
        toastMessage: '',
        toastKeyTranslate: '',
        errorCode: '',
      });
    };

    useEffect(() => {
      if (isShowToast) {
        toast[toastType](toastMessage, {
          onClose: resetResponseStatusContext,
        });
      }
    }, [isShowToast]); // eslint-disable-line

    return <WrappedComponent />;
  }
  return HOC;
}
